import { MouseEvent, PureComponent, useEffect } from 'react';

import CheckoutAddressBook from 'Component/CheckoutAddressBook';
import CheckoutPayments from 'Component/CheckoutPayments';
import CheckoutTermsAndConditionsPopup from 'Component/CheckoutTermsAndConditionsPopup';
import Field from 'Component/Field';
import { FieldType } from 'Component/Field/Field.config';
import { StoreInPickUpCode } from 'Component/StoreInPickUp/StoreInPickUp.config';
import { CheckoutSteps } from 'Route/Checkout/Checkout.config';
import { formatPrice } from 'Util/Price';
import TamaraQuery from '../../query/Tamara.query';
import { prepareQuery } from 'Util/Query';
import { executePost } from 'Util/Request/Request';
import Loader from 'Component/Loader';
import CheckoutDeliveryOptions from 'Component/CheckoutDeliveryOptions';
import { deleteCartId, getCartId, getCartTotalSubPrice } from 'Util/Cart';

import TabbyQuery from '../../query/Tabby.query';
import { fetchQuery } from 'Util/Request/Query';

import './OnepageCheckoutBilling.style';
import OtpInput from 'Component/OtpInput';
import { HYPERPAY_APPLEPAY, HYPERPAY_CARDS, HYPERPAY_MADA, TAMARA_PAYMENT, TABBY_PAYMENT } from 'Route/OnepageCheckout/OnepageCheckout.component';

export const COD_PAYMENT = 'phoenix_cashondelivery';

/** @namespace AlmusbahblendPwa/Component/OnepageCheckoutBilling/Component */
export class OnepageCheckoutBillingComponent extends PureComponent {
    state = {
        isOrderButtonVisible: true,
        isOrderButtonEnabled: true,
        isTACAccepted: true,
        tamaraLocale: 'en',
        tamaraCountry: 'SA',
        tamaraCurrency: 'SAR',
        tamaraPublicKey: null,
        tamaraInstalmentWidgetUrl: 'https://cdn.tamara.co/widget/installment-plan.min.js',
        isLoading: true,
        enableOtpComponent: false,
        tabbyPublicKey: '',
        baseGrandTotalValue: 0,
    };

    static defaultProps = {
        cartTotalSubPrice: null,
    };

    __construct(props) {
        super.__construct?.(props);

        this.setOrderButtonEnableStatus = this.setOrderButtonEnableStatus.bind(this);
        this.setTACAccepted = this.setTACAccepted.bind(this);
        this.handleShowPopup = this.handleShowPopup.bind(this);
    }

    componentDidMount() {
        const { termsAreEnabled, currentDeliveryAddress, setTACAccepted } = this.props;

        if (!termsAreEnabled) {
            this.setState({ isOrderButtonEnabled: true });
        }

        setTACAccepted(true);

        // if (currentDeliveryAddress.country_id == 'SA') {
        //     setTACAccepted(true);
        // } else {
        //     setTACAccepted(false);
        // }
        this.getGrandtotalBasePrice();
        this.getTamaraConfigData();
    }

    async getGrandtotalBasePrice() {
        const {
            totals,
        } = this.props;

        const cartId = getCartId();

        const grnadTotal = totals.prices.grand_total.value;
        
        const baseGrandTotal = await fetchQuery(TabbyQuery.getGrandTotalBaseCurrencyValue(cartId));
        // console.log("baseGrandTotalValue: ", baseGrandTotal);
        this.setState({
            baseGrandTotalValue: baseGrandTotal.getbasecurrencyvalue.final_base_currency_value || grnadTotal,
        });
    }

    // componentDidUpdate() {
    //     const { termsAreEnabled, currentDeliveryAddress, setTACAccepted } = this.props;
    //     const { isTACAccepted } = this.state;

    //     setTACAccepted(true);

    //     if (currentDeliveryAddress.country_id == 'SA') {
    //         setTACAccepted(true);
    //     } else if (isTACAccepted == false) {
    //         setTACAccepted(false);
    //     }
    // }

    setOrderButtonEnableStatus(isOrderButtonEnabled) {
        const { setOrderButtonEnableStatus } = this.props;
        
        this.setState({ isOrderButtonEnabled });
        setOrderButtonEnableStatus(isOrderButtonEnabled);
    }

    setTACAccepted() {
        return;
        const { setTACAccepted } = this.props;
        const { isTACAccepted } = this.state;
        this.setState(({ isTACAccepted: oldIsTACAccepted }) => ({
            isTACAccepted: !oldIsTACAccepted,
        }));
        
        setTACAccepted(!isTACAccepted);
    }

    handleShowPopup(e) {
        const { showPopup } = this.props;

        e.preventDefault();
        showPopup();
    }

    renderDelivery() {
        const {
            shippingMethods,
            onShippingMethodSelect,
            handleSelectDeliveryMethod,
            selectedShippingMethod,
        } = this.props;

        // console.log("Shipping props: ", [shippingMethods,
            // onShippingMethodSelect,
            // handleSelectDeliveryMethod,
            // selectedShippingMethod]);

        return (
            <CheckoutDeliveryOptions
              shippingMethods={ shippingMethods }
              onShippingMethodSelect={ onShippingMethodSelect }
              handleSelectDeliveryMethod={ handleSelectDeliveryMethod }
              selectedShippingMethod={ selectedShippingMethod }
            />
        );
    }

    renderTAC() {
        const {
            termsAreEnabled,
            termsAndConditions,
        } = this.props;

        const {
            checkbox_text = __('I agree to terms and conditions'),
        } = termsAndConditions[0] || {};

        const { isTACAccepted } = this.state;

        if (!termsAreEnabled) {
            return null;
        }

        return (
            <div
              block="CheckoutBilling"
              elem="TermsAndConditions"
            >

                <label
                  block="CheckoutBilling"
                  elem="TACLabel"
                  htmlFor="termsAndConditions"
                >
                    <Field
                      type={ FieldType.CHECKBOX }
                      attr={ {
                          id: 'termsAndConditions',
                          name: 'termsAndConditions',
                          value: 'termsAndConditions',
                          checked: isTACAccepted,
                      } }
                    //   events={ {
                    //       onChange: this.setTACAccepted,
                    //   } }
                      mix={ { block: 'CheckoutBilling', elem: 'TermsAndConditions-Checkbox' } }
                    />
                   <div>
                        { `${checkbox_text } - ` }
                        <button
                          block="CheckoutBilling"
                          elem="TACLink"
                          onClick={ this.handleShowPopup }
                          type="button"
                        >
                            { __('read more') }
                        </button>
                   </div>
                </label>
            </div>
        );
    }

    renderOrderTotalExlTax() {
        const {
            cartTotalSubPrice,
            totals: { prices: { quote_currency_code = null } = {} },
        } = this.props;

        if (!cartTotalSubPrice) {
            return null;
        }

        const orderTotalExlTax = formatPrice(cartTotalSubPrice, quote_currency_code);

        return (
            <span>
                { `${ __('Excl. tax:') } ${ orderTotalExlTax }` }
            </span>
        );
    }

    renderOrderTotal() {
        const {
            totals: {
                prices: {
                    grand_total: {
                        value: grand_total = 0,
                    } = {},
                    quote_currency_code = null,
                } = {},
            },
        } = this.props;

        const orderTotal = formatPrice(grand_total || 0, quote_currency_code);

        return (
            <dl block="Checkout" elem="OrderTotal">
                <dt>
                    { __('Order total:') }
                </dt>
                <dd>
                    { orderTotal }
                    { this.renderOrderTotalExlTax() }
                </dd>
            </dl>
        );
    }

    renderAddressBook() {
        const {
            onAddressSelect,
            isSameAsShipping,
            totals: { is_virtual },
        } = this.props;

        if (isSameAsShipping && !is_virtual) {
            return null;
        }

        return (
            <CheckoutAddressBook
              onAddressSelect={ onAddressSelect }
              isBilling
              is_virtual
            />
        );
    }

    renderSameAsShippingCheckbox() {
        const {
            isSameAsShipping,
            onSameAsShippingChange,
            totals: { is_virtual },
            selectedShippingMethod,
        } = this.props;

        if (is_virtual) {
            return null;
        }

        if (selectedShippingMethod === StoreInPickUpCode.METHOD_CODE) {
            return null;
        }

        return (
            <Field
              type={ FieldType.CHECKBOX }
              attr={ {
                  id: 'sameAsShippingAddress',
                  name: 'sameAsShippingAddress',
                  value: 'sameAsShippingAddress',
                  checked: isSameAsShipping /* && selectedShippingMethod !== StoreInPickUpCode.METHOD_CODE */,
              } }
              events={ {
                  onChange: onSameAsShippingChange,
              } }
              mix={ { block: 'CheckoutBilling', elem: 'Checkbox' } }
              label={ __('My billing and shipping are the same') }
              isDisabled={ isSameAsShipping /*selectedShippingMethod === StoreInPickUpCode.METHOD_CODE */ }
            />
        );
    }

    renderHeading() {
        return (
            <h2 block="Checkout" elem="Heading">
                { __('Billing address') }
            </h2>
        );
    }

    renderAddresses() {
        return (
            <>
                { this.renderHeading() }
                { this.renderSameAsShippingCheckbox() }
                { this.renderAddressBook() }
            </>
        );
    }
    
    agentHas(keyword) {
        return navigator.userAgent.toLowerCase().search(keyword.toLowerCase()) > -1;
    }

    renderPayments() {
        const {
            paymentMethods,
            onPaymentMethodSelect,
            paymentMethod,
            totals,
            currentDeliveryAddress,
            email,
            tabbyErrorMessage,
            isTabbyDisabled,
            // setLoading,
            // setDetailsStep,
            // shippingAddress
        } = this.props;

        if (!paymentMethods.length) {
            return <Loader isLoading= {true} />;
            // return null;
        }

        let selectedPaymentMethods = paymentMethods;

        let iOS = (!!window.ApplePaySetupFeature || !!window.safari) && this.agentHas("Safari") && !this.agentHas("Chrome") && !this.agentHas("CriOS");
        
        // console.log("iOS->: ", iOS);

        if (!iOS) {
            const items = paymentMethods.filter(item => item.code !== "HyperPay_ApplePay");
            selectedPaymentMethods = items;
        }

        return (
            <CheckoutPayments
            //   setLoading={ setLoading }
            //   setDetailsStep={ setDetailsStep }
                selectedPaymentMethod={ paymentMethod }
                paymentMethods={ selectedPaymentMethods }
                onPaymentMethodSelect={ onPaymentMethodSelect }
            //   setOrderButtonVisibility={ this.setOrderButtonVisibility }
                currentDeliveryAddress={ currentDeliveryAddress }
                email={ email }
                setOrderButtonEnableStatus={ this.setOrderButtonEnableStatus }
                totals={ totals }
                tabbyErrorMessage={ tabbyErrorMessage }
                isTabbyDisabled={ isTabbyDisabled }
            />
        );
    }

    renderPopup() {
        const {
            termsAndConditions,
        } = this.props;

        const {
            name = '',
            content = '',
        } = termsAndConditions[0] || {};

        const payload = {
            title: name, //termsAndConditions[0].name,
            text: content //termsAndConditions[0].content
        }

        return <CheckoutTermsAndConditionsPopup payload={payload} />;
    }

    renderTamaraBlock() {
        const {
            totals,
            paymentMethod,
        } = this.props;
        const {
            isLoading,
            tamaraCountry,
            tamaraLocale,
            tamaraCurrency,
            tamaraPublicKey,
            tamaraInstalmentWidgetUrl,
        } = this.state;

        // console.log("renderTamaraBlock P method:", paymentMethod);

        if (!paymentMethod.includes(TAMARA_PAYMENT)) {
            return null;
        }
        
        let text = paymentMethod;
        const myArray = text.split(TAMARA_PAYMENT);
        let installmentNumber = 3;
        if (myArray[1] != '') {
            installmentNumber = Number(myArray[1].replace("_", ""));
        }
        
        if (isLoading) {
           return <Loader isLoading />;
        }
        // // console.log("totals in checkout billing: ", totals);
        const grnadTotal = totals.prices.grand_total.value;
        // // const script = document.createElement('script');
        // const scriptTwo = document.createElement('script');
        // // script.src = `https://cdn.tamara.co/widget-v2/tamara-widget.js`;
        // scriptTwo.src = tamaraInstalmentWidgetUrl ;
        // // script.async = true;
        // scriptTwo.async = true;
        // // document.body.appendChild(script);
        // document.body.appendChild(scriptTwo);
        setTimeout(() => {
            if (window.TamaraInstallmentPlan) {
              window.TamaraInstallmentPlan.init({ lang: tamaraLocale, country: tamaraCountry,
              publicKey: { tamaraPublicKey } })
              window.TamaraInstallmentPlan.render()
            }
          }, 2000)

        // setTimeout(() => {
        //     if (window.tamaraWidgetConfig) {
        //         console.log("If tamaraPublicKey: ", tamaraPublicKey);
        //       window.tamaraWidgetConfig.init({ 
        //         lang: tamaraLocale, 
        //         country: tamaraCountry,
        //         publicKey: { tamaraPublicKey } 
        //       })
        //       window.tamaraWidgetConfig.render()
        //     }
        //   }, 2000)

        return (
            <div class="CheckoutBilling-TamaraWidget">
                {/* <div class="tamara-widget"
                    data-lang="en"
                    data-inject-template="false"
                    data-currency={tamaraCurrency}
                    data-country-code={tamaraCountry}
                    data-installment-available-amount={grnadTotal}>
                </div> */}
                {/* <div class="tamara-widget">
                    <tamara-widget amount= {grnadTotal} inline-type="3">
                    </tamara-widget>
                </div> */}

                <div class="tamara-installment-plan-widget" data-lang={tamaraLocale} data-price={grnadTotal} data-currency={tamaraCurrency} data-color-type="default" data-country-code="SA" data-number-of-installments={installmentNumber} ></div>
            </div>
        );
        
    }

    renderTabbyBlock() {
        const {
            totals,
            paymentMethod,
            currencyCode,
            isTabbyDisabled,
        } = this.props;

        const {
            isLoading,
            tamaraCountry,
            tamaraLocale,
            tamaraCurrency,
            tabbyPublicKey,
            baseGrandTotalValue,
        } = this.state;

        if (tabbyPublicKey == '' || isTabbyDisabled) {
            return null;
        }

        if (document.getElementById("TabbyCard")) {
            if (paymentMethod.includes(TABBY_PAYMENT) && !isTabbyDisabled) {
                // console.log("Billing Component isTabbyDisabled if: ", isTabbyDisabled);
                document.getElementById("TabbyCard").style.display = "block";
            } else {
                // console.log("Billing Component isTabbyDisabled else: ", isTabbyDisabled);
                document.getElementById("TabbyCard").style.display = "none";
                return null;
            }
        }
        
        const grnadTotal = baseGrandTotalValue || totals.prices.grand_total.value;

        const script = document.createElement('script');
        script.src = 'https://checkout.tabby.ai/tabby-card.js';
        script.async = true;
        script.onload = () => {
    
            if (window.TabbyCard) {
            new window.TabbyCard({
                selector: '#TabbyCard',
                currency: 'SAR',
                price:grnadTotal,
                lang: tamaraLocale, 
                source: 'product', 
                publicKey: tabbyPublicKey,
                merchantCode: 'string',
                size: 'narrow',
                theme: 'black',
                header: false
            });
            }
        };
        document.body.appendChild(script);
    
        return () => {
            document.body.removeChild(script);
        };
    }

    getTamaraConfigData() {
        const query = TamaraQuery.getTamaraPaymentConfig();
        executePost(prepareQuery([query])).then(
            /** @namespace CheckoutBilling/Component/CheckoutBilling/Container/executeGet/then */
            ( data ) => {
                // console.log("=== getTamaraPaymentConfig data: ", data.gettamarapaymentconfig);
                this.setState({
                    isLoading: false,
                    tamaraCountry: data.gettamarapaymentconfig.tamaraCountry,
                    tamaraCurrency: data.gettamarapaymentconfig.tamaraCurrency,
                    tamaraLocale: data.gettamarapaymentconfig.tamaraLocale,
                    tamaraPublicKey: data.gettamarapaymentconfig.tamaraPublicKey,
                    tamaraInstalmentWidgetUrl: data.gettamarapaymentconfig.tamaraInstalmentWidgetUrl,
                    enableOtpComponent: data.gettamarapaymentconfig.codOtpEnable,
                    tabbyPublicKey: data.gettamarapaymentconfig.tabbyPublicKey,
                })
            }
        ).catch(
            /** @namespace CheckoutBilling/Component/CheckoutBilling/Container/executeGet/then/catch */
            () => {
                this.setState({ isLoading: false })
            }
        );
    }

    renderOtpBlock() {
        const { isOtpValidated } = this.props;
        const { paymentMethod, currentDeliveryAddress, validateMobileOtp } = this.props;
        const { enableOtpComponent } = this.state;

        const {
            telephone: mobileNumber = '',
        } = currentDeliveryAddress;

        if (paymentMethod != COD_PAYMENT) {
            return null;
        }

        if (!enableOtpComponent) {
            validateMobileOtp(true);
            return null;
        }

        if(isOtpValidated) {
            return (
                <div block="OtpInput">
                    <div block="SuccessMsg">
                        <p>Phone number verified with given OTP.</p>
                    </div>
                </div>
            );
        }

        if (mobileNumber== undefined || mobileNumber.length < 11) {
            return (
                <div block="OtpInput">
                    <div block="ErrMsg">
                        <p>Invalid mobile number given to send OTP.</p>
                    </div>
                </div>
            );
        }

        return (
            <OtpInput 
                validateMobileOtp={validateMobileOtp} 
                mobileNumber={mobileNumber} 
            />
        );
    }

    render() {
        const { isLoading, paymentMethod, currentDeliveryAddress } = this.props;

        // if (paymentMethod != COD_PAYMENT) {
        //     this.setState({ isOtpValidated: true});
        // } else {
        //     this.setState({ isOtpValidated: false});
        // }

        return (
            <div block="OnepageCheckoutBilling">
                { /* TODO: Implement render method */ }
                <div>
                    <Loader isLoading={ isLoading } />
                    { this.renderDelivery() }
                </div>
                { this.renderAddresses() }
                <div block="OnepageCheckoutBilling-Payments">
                    { this.renderPayments() }
                    { this.renderTamaraBlock() }
                    { this.renderTabbyBlock() }
                    { this.renderOtpBlock() }
                </div>
                
                { this.renderTAC() }
                { this.renderPopup() }
            </div>
        );
    }
}

export default OnepageCheckoutBillingComponent;
